import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Empty, Form, Input, Modal, Popconfirm, Table, Tag } from 'antd'
import i18next from 'i18next'
import { debounce, get, isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined, PlusCircleOutlined, PlusOutlined, QrcodeOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'
import { getFormInitialValues, initialize } from 'redux-form'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { useReactToPrint } from 'react-to-print'
// eslint-disable-next-line import/no-cycle
import moment from 'moment'
// eslint-disable-next-line import/no-cycle
import { deleteStoreItem, getStoreItem, getStoreItems, IStoreItemForm, StoreItemTableItem } from '../../reducers/storeItems/actions'
import { history } from '../../utils/history'
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../reducers'
import { FORM, FORMS, STORE_ITEM_TRANSACTION_TYPE } from '../../utils/enums'
import { ComponentToPrint } from '../../components/QrCode/ComponentToPrint'
// eslint-disable-next-line import/no-cycle
import SelectStoreField from '../../components/SelectStoreField'
// eslint-disable-next-line import/no-named-as-default
import TableToPrint from '../../components/QrCode/TableToPrint'
// eslint-disable-next-line import/no-cycle
import CreateStoreItemTransactionModal from './components/CreateStoreItemTransactionModal'

type Columns = ColumnsType<StoreItemTableItem>

interface IInitFilter {
	jobID: number | null;
	jobToID: number | null | undefined;
}

type Props = {
	tableMode: Boolean;
	tableData: StoreItemTableItem[] | null | undefined;
	initFilter: IInitFilter | null;
	addRemoveItemFeature: Boolean;
};

function createColorHash(colors: string[]) {
	const colorMap: { [key: string]: string } = {}

	return (str: string) => {
		if (colorMap[str]) {
			return colorMap[str]
		}
		const hash = str?.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0)
		const colorIndex = hash % colors.length
		colorMap[str] = colors[colorIndex]
		return colors[colorIndex]
	}
}

const colorList = [
	'magenta',
	'red',
	'volcano',
	'orange',
	'gold',
	'lime',
	'green',
	'cyan',
	'blue',
	'geekblue',
	'purple'
]

export const colorHash = createColorHash(colorList)

export interface CreateTransactionInTableProps {
	isShowed: boolean;
	type: STORE_ITEM_TRANSACTION_TYPE;
	storeItemName: string | null;
	storeItemID: number | null;
}

const StoreItems = (props: Props = {
	tableData: null,
	initFilter: null,
	tableMode: false,
	addRemoveItemFeature: true
}) => {
	const dispatch = useDispatch()
	const { t } = useTranslation('pages')
	const filterJobID = props.initFilter?.jobID
	const jobToID = props.initFilter?.jobToID
	const { tableData } = props

	const [visibleGenerateQrCodeModal, setVisibleGenerateQrCodeModal] = useState(false)
	const [visibleGenerateItemListModal, setVisibleGenerateItemListModal] = useState(false)
	const [jobIdLabel, setJobIdLabel] = useState('')
	const dataSource = useSelector((state: RootState) => state.storeItems.storeItems)
	const filterInitialValues = useSelector((state: RootState) => getFormInitialValues(FORM.STORE_ITEM_FILTER)(state))
	const hasPermissionToAddStoreItem = useSelector(
		(state: RootState) => state.profile.profile.data?.hasPermissionToAddStoreItems
	) || false

	const componentRef = useRef(null)

	const handlePrint = useReactToPrint({
		content: () => componentRef.current
	})

	const [createTransactionModal, setCreateTransactionModal] =		useState<CreateTransactionInTableProps>({
		isShowed: false,
		storeItemID: null,
		storeItemName: null,
		type: STORE_ITEM_TRANSACTION_TYPE.REGULAR
	})

	const [successRerender, setSuccessRerender] = useState(0)

	const initDetailForm = (data: IStoreItemForm) => {
		dispatch(initialize(FORMS.STORE_ITEM_FORM, data))
	}

	const onStoreItemTransactionSuccess = () => {
		setSuccessRerender(successRerender + 1)
		if (createTransactionModal.storeItemID != null) {
			dispatch(
				getStoreItem(createTransactionModal.storeItemID, (data) => {
					const initValues: IStoreItemForm = {
						id: data.data.id,
						isLiquid: data.data.isLiquid,
						isReplaceable: data.data.isReplaceable,
						idealStockPercentage: data.data.idealStockPercentage,
						activeJobs: data?.data?.activeJobs,
						updatedAt: moment(data.data.updatedAt).format('D. MMM YYYY HH:mm') || '-',
						allAvailableStock: data.data.allAvailableStock,
						amountOfStockInAddresses: data.data.amountOfStockInAddresses,
						nameSlug: data.data.nameSlug,
						name: data.data.name
					}

					initDetailForm(initValues)
					setCreateTransactionModal({
						...createTransactionModal,
						storeItemID: data.data.id,
						storeItemName: data.data?.name,
						isShowed: false
					})
				})
			)
		}
	}

	const [query, setQuery] = useQueryParams({
		search: StringParam,
		limit: withDefault(NumberParam, 40),
		page: withDefault(NumberParam, 1),
		jobID: withDefault(NumberParam, filterJobID),
		jobToID: withDefault(NumberParam, jobToID),
		orderBy: withDefault(StringParam, 'name'),
		orderDirection: withDefault(StringParam, null)
	})

	useEffect(() => {
		if (props.tableData == null) {
			dispatch(
				getStoreItems(query.page, query.limit, {
					search: query.search,
					jobID: query.jobID,
					orderBy: query.orderBy,
					orderDirection: query.orderDirection
				})
			)
		}
	}, [
		dispatch,
		query.search,
		query.limit,
		query.page,
		query.orderBy,
		query.orderDirection,
		query.jobID,
		successRerender
	])

	useEffect(() => {
		if (!filterInitialValues || isEmpty(filterInitialValues)) {
			const initData = {
				search: query.search || null,
				jobID: 320
			}
			dispatch(initialize(FORM.STORE_ITEM_FILTER, initData))
		}
	}, [dispatch, filterInitialValues, query.search])

	const columns: Columns = useMemo(() => [
		{
			title: i18next.t('pages:Názov'),
			dataIndex: 'name',
			key: 'name',
			ellipsis: true,
			sorter: true,
			width: 210,
			render: (value) => value || '-'
		},
		{
			title: `${i18next.t('pages:Počet')} ${jobIdLabel?.length > 0 ? ` (${jobIdLabel})` : ''}`,
			dataIndex: 'count',
			key: 'count',
			width: 210,
			sorter: true,
			render(count, record: StoreItemTableItem) {
				return (
					<div>
						<span>{count}</span>
						{record?.isInVirtualStore
							&& !record.isCountOkeyCompareToIdealStock && (
							<Tag className={'ml-3'} color={'volcano'} key={record?.id}>
									odporúčanie: <b>{record?.idealStockCount}</b>
							</Tag>
						)}
					</div>
				)
			}
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: !props.tableMode || props.addRemoveItemFeature ? 20 : 0,
			render(text, record) {
				return (
					hasPermissionToAddStoreItem && (
						<div className={'flex justify-center'}>
							{!props.tableMode ? (
								<Popconfirm
									title={i18next.t('pages:Skutočne chcete vymazať záznam?')}
									icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
									cancelText={i18next.t('pages:Zrušiť')}
									okText={i18next.t('pages:Vymazať')}
									onConfirm={(e) => {
										e?.stopPropagation()
										dispatch(deleteStoreItem(record.id, () => {
											dispatch(getStoreItems(1, query.limit))
										}))
									}}
									onCancel={(e) => e?.stopPropagation()}
									okButtonProps={{
										size: 'small',
										type: 'primary'
									}}
									cancelButtonProps={{
										size: 'small',
										type: 'ghost'
									}}
								>
									<Button
										icon={<DeleteOutlined />}
										type={'primary'}
										onClick={(e) => e.stopPropagation()}
									/>
								</Popconfirm>
							) : (
								<div />
							)}
							{props.addRemoveItemFeature && (
								<PlusOutlined
									onClick={(e) => {
										setCreateTransactionModal({
											...createTransactionModal,
											storeItemID: record.id,
											storeItemName: record.name,
											isShowed: true
										})
										e.stopPropagation()
									}}
								/>
							)}
						</div>
					)
				)
			}
		}
	], [hasPermissionToAddStoreItem, jobIdLabel, props.addRemoveItemFeature, props.tableMode])

	const columnsToPrint: Columns = useMemo(() => [
		{
			title: i18next.t('pages:Názov'),
			dataIndex: 'name',
			key: 'name',
			ellipsis: true,
			sorter: true,
			width: 210,
			render: (value) => value || '-'
		},
		{
			title: `${i18next.t('pages:Počet')} ${jobIdLabel?.length > 0 ? ` (${jobIdLabel})` : ''}`,
			dataIndex: 'count',
			key: 'count',
			width: 210,
			sorter: true,
			render(count) {
				return <span>{count}</span>
			}
		}
	], [jobIdLabel])

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: Record<string, FilterValue | null>,
		sorter: SorterResult<StoreItemTableItem> | SorterResult<StoreItemTableItem>[],
		extra: TableCurrentDataSource<StoreItemTableItem>
	) => {
		let order = {}
		if ((sorter as SorterResult<any>)?.order) {
			const sorterLocal = sorter as SorterResult<any>
			order = {
				orderBy: sorterLocal.field,
				orderDirection: sorterLocal.order === 'ascend' ? 'asc' : 'desc'
			}
		}

		setQuery({
			...query,
			limit: query.limit,
			page: pagination.current,
			...order
		})
	}

	const debounced = useCallback(
		debounce((searchTerm: string) => setQuery({ ...query, search: searchTerm, limit: 30 }), 300),
		[query]
	)

	const changeLimit = useCallback(debounce((limit: number) => setQuery({ ...query, page: 1, limit }), 300), [
		query
	])

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		debounced(e.target.value)
	}

	return (
		<div className={'page-wrapper'}>
			{!props.tableMode ? (
				<div className={'flex justify-between'}>
					<div>
						<Form.Item>
							<Input.Search onChange={handleOnChange} style={{ width: 300 }} allowClear />
						</Form.Item>
						<Form.Item>
							<SelectStoreField
								initWithVirtualStore={true}
								onValueChange={(data) => {
									setQuery({
										...query,
										jobID: data.value,
										page: 1
									})
									setJobIdLabel(data.label)
								}}
								storeValue={query.jobID || undefined}
							/>
						</Form.Item>
					</div>
					<div>
						<Button
							icon={<QrcodeOutlined />}
							className={'ml-4'}
							type={'primary'}
							onClick={() => setVisibleGenerateItemListModal(true)}
						>
							{t('pages:Export položiek do pdf')}
						</Button>
						<Button
							icon={<QrcodeOutlined />}
							className={'ml-4'}
							type={'primary'}
							onClick={() => setVisibleGenerateQrCodeModal(true)}
						>
							{t('pages:Generuj Qr Kody')}
						</Button>
						{hasPermissionToAddStoreItem && (
							<Button
								className={'ml-4'}
								icon={<PlusCircleOutlined />}
								href={t('paths:storeItemCreate|path')}
								type={'primary'}
							>
								{t('pages:Pridať skladovú položku')}
							</Button>
						)}
					</div>
				</div>
			) : (
				<div />
			)}
			<Table
				className={'general-table'}
				columns={columns}
				dataSource={tableData || dataSource.tableData}
				onChange={handleTableChange}
				style={{ marginTop: 0 }}
				showSorterTooltip={false}
				rowClassName={(record, index) => (!record.isCountOkeyCompareToIdealStock && record.isInVirtualStore ? 'red-row' : '')
				}
				pagination={
					props.tableData != null
						? false
						: {
							total: dataSource.pagination?.totalCount,
							current: dataSource.pagination?.page,
							showSizeChanger: true,
							pageSize: query.limit,
							pageSizeOptions: ['40', '100', '1000'],
							onShowSizeChange: (current, size) => {
								changeLimit(size)
							}
						}
				}
				loading={dataSource.isLoading}
				onRow={(record) => ({
					onClick: () => history.push(`${t('paths:storeItemDetail|path')}/${get(record, 'id')}`)
				})}
				locale={{
					emptyText: <Empty description={t('pages:Žiadne dáta')} />
				}}
				size={'small'}
			/>
			<Modal
				title={t('pages:Vytlač qr kódy')}
				centered
				visible={visibleGenerateQrCodeModal}
				onOk={() => {
					setVisibleGenerateQrCodeModal(false)
					if (handlePrint) {
						handlePrint()
					}
				}}
				onCancel={() => setVisibleGenerateQrCodeModal(false)}
				width={1000}
			>
				<Button type={'primary'} onClick={handlePrint}>
					{t('pages:Vytlačiť')}
				</Button>
				<ComponentToPrint
					ref={componentRef}
					list={dataSource.tableData.map((data) => ({
						name: data.name,
						data: {
							storeItemId: data.id,
							storeItemName: data.name
						}
					}))}
				/>
			</Modal>
			<Modal
				title={t('pages:Vytlač zoznam skladových položiek')}
				centered
				visible={visibleGenerateItemListModal}
				onOk={() => {
					setVisibleGenerateItemListModal(false)
					if (handlePrint) {
						handlePrint()
					}
				}}
				onCancel={() => setVisibleGenerateItemListModal(false)}
				width={1000}
			>
				<Button type={'primary'} onClick={handlePrint}>
					{t('pages:Vytlačiť')}
				</Button>
				<TableToPrint ref={componentRef}>
					<Table
						className={'general-table'}
						columns={columnsToPrint}
						dataSource={tableData || dataSource.tableData}
						onChange={handleTableChange}
						style={{ marginTop: 0 }}
						showSorterTooltip={false}
						pagination={false}
						locale={{
							emptyText: <Empty description={t('pages:Žiadne dáta')} />
						}}
						size={'small'}
					/>
				</TableToPrint>
			</Modal>
			{createTransactionModal.storeItemID && createTransactionModal.storeItemName && (
				<CreateStoreItemTransactionModal
					width={500}
					transaction={{
						amount: 1,
						storeItemName: createTransactionModal.storeItemName || '',
						storeItemID: createTransactionModal.storeItemID,
						type: STORE_ITEM_TRANSACTION_TYPE.REGULAR,
						jobToID: query.jobToID // this is how we pass the jobToID filterJobID || null
					}}
					isVisible={createTransactionModal.isShowed}
					onCancel={() => {
						setCreateTransactionModal({
							isShowed: false,
							storeItemID: null,
							storeItemName: null,
							type: STORE_ITEM_TRANSACTION_TYPE.REGULAR
						})
					}}
					onSuccess={onStoreItemTransactionSuccess}
				/>
			)}
		</div>
	)
}

export default StoreItems
