import { get, map, mapValues } from 'lodash'
// eslint-disable-next-line import/no-cycle
import moment from 'moment'
// eslint-disable-next-line import/no-cycle
import { deleteReq, getReq, postReq, putReq } from '../../utils/request'
import { CREATE_JOBS, LOAD_JOB, LOAD_SELECT_JOBS, REMOVE_JOB, UPDATE_JOBS } from './types'
import { IResetStore } from '../generalTypes'
// eslint-disable-next-line import/no-cycle
import { IUserRating, IUsersPayload } from '../usersU/actions'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'
import { IResponsePagination } from '../../types/interfaces'
// eslint-disable-next-line import/no-cycle
import { STORE_TYPE } from '../../pages/stores/Stores'
import { PRICE_OFFER_STATUS } from '../../utils/enums'
import { CREATE_PRICE_OFFERS } from '../priceOffers/types'

export type IJobActions = IResetStore
	| IGetJobsActions
	| IGetJobAction

// job
interface IGetJobAction {
	type: LOAD_JOB
	payload: IJobPayload
}

// jobs
interface IGetJobsActions {
	type: LOAD_SELECT_JOBS
	payload: IJobsPayload
}

export interface IJobsPayload {
	tableData: JobTableItem[]
	originalData: JobOriginalItem[]
	pagination: IResponsePagination | null
}

export interface IQrScansRecordOriginItem {
	id: string
	scanDate: string
	scanDateTimeStamp: number
}

export interface IQrScansOriginTableItem {
	date: string
	duration: string
	from: string
	to: string
	firstScan: IQrScansRecordOriginItem
	lastScan: IQrScansRecordOriginItem
}

export interface ITrusteeOriginTableItem {
	email: string
	id: string
	name: number
	phone: number
	sendEmail: boolean
}

export interface JobOriginalItem {
	id: number
	address: string
	note: string
	objectTrusteeEmail: string
	ownerEmail: string
	createdAt: string
	updatedAt: string
	user: string
	trustees: ITrusteeOriginTableItem[]
	qrScans: IQrScansOriginTableItem[]
}

export interface ITrusteeTableItem {
	email: string | null,
	sendEmail: boolean,
}

export interface JobTableItem {
	id: number
	key: number
	address: string
	note: string
	objectTrusteeEmail: string
	ownerEmail: string
	createdAt: string
	updatedAt: string
	user: string
	qrScan: IQrScansOriginTableItem
	trustees: ITrusteeTableItem[]
}

export interface IJobUser {
	id: number
	email: string
	firstName: string
	lastName: string
	role: string
	isConfirmed: boolean
	phone: string
}

export const getJobs = (
	params: IGetJobParams,
	onSuccess: (data: IJobsPayload) => void = ((data) => {})
): ThunkResult<Promise<IJobsPayload>> => async (dispatch) => {
	let payload = {} as IJobsPayload
	try {
		dispatch({ type: LOAD_SELECT_JOBS.START })

		const queries = {
			limit: params.limit,
			page: params.page,
			...params.queryParams
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq('/api/admin/jobs', normalizeQueryParams)

		const originalData: JobOriginalItem[] = get(data, 'jobs', [])
		const tableData: JobTableItem[] = map(originalData, (column) => ({
			key: column.id,
			id: column.id,
			note: column.note,
			address: column.address,
			user: column.user,
			qrScan: column?.qrScans[0] || null,
			trustees: column.trustees?.map((data) => ({
				email: data?.email || null,
				sendEmail: data?.sendEmail || false
			})).filter((data) => data.email != null) || [] as ITrusteeTableItem[],
			ownerEmail: column.ownerEmail,
			objectTrusteeEmail: column.objectTrusteeEmail,
			createdAt: moment(column.createdAt).format('D. MMM YYYY HH:mm') || '-',
			updatedAt: moment(column.updatedAt).format('D. MMM YYYY HH:mm') || '-'
		}))

		payload = {
			tableData,
			originalData,
			pagination: get(data, 'context')
		}

		dispatch({
			type: LOAD_SELECT_JOBS.DONE,
			payload
		})

		onSuccess(payload)
		return data
	} catch (error) {
		dispatch({ type: LOAD_SELECT_JOBS.FAILED })
		Promise.reject(error)
		return error
	}
}

export interface IGetJobStoreItemsParams{
	limit: number | null
	page: number | null
	queryParams: any
}

export interface JobStoreItem {
	id: number
	address: string
	storeType: STORE_TYPE | null
	storeItemsCount: number,
}

export interface IJobsStoreItemsPayload {
	jobs: JobStoreItem[],
	context: IResponsePagination
}

export const getJobsStoreItems = (
	params: IGetJobStoreItemsParams,
	onSuccess?: (data: IJobsStoreItemsPayload) => void
): ThunkResult<Promise<IJobsStoreItemsPayload>> => async (dispatch) => {
	try {
		const queries = {
			limit: params.limit,
			page: params.page,
			...params.queryParams
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq('/api/admin/jobs/store-items', normalizeQueryParams)

		if (onSuccess) {
			onSuccess(data)
		}
		return data
	} catch (error) {
		Promise.reject(error)
		return error
	}
}

export interface IPriceOfferTrustee {
	exists: boolean,
	name: string,
	phoneOfTrustee: string,
	emailOfTrustee: string,
	userWithSameEmail: {
		id: number,
		name: string
	} | null,
	userWithSamePhone: {
		id: number,
		name: string
	} | null
}

export interface IJobForm {
	address: string
	note: string
	userID: number
	priceForCleaning: number
	objectTrusteeEmail: string
	ownerEmail: string
	extras: IExtras

	tuesdayTasks: IUserJobTask[]
	thursdayTasks: IUserJobTask[]
	wednesdayTasks: IUserJobTask[]
	fridayTasks: IUserJobTask[]
	saturdayTasks: IUserJobTask[]
	sundayTasks: IUserJobTask[]
	mondayTasks: IUserJobTask[]
	monthTasks: IUserJobTask[]
	quarterYearTasks: IUserJobTask[]
	halfYearTasks: IUserJobTask[]
	yearTasks: IUserJobTask[]

	jobGroups: IJobGroup[]
	trustees: ITrusteeParam[]

	priceOfferTrustee: IPriceOfferTrustee

	inflationPartAccepted: boolean
	nameOfHousingAssociation: string
	comment: string
	floors: number
	flats: number
}

export const createJobs = (
	values: IJobForm,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onSuccess: (data: any) => void = ((data) => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
) : ThunkResult<Promise<Boolean>> => async (dispatch) => {
	dispatch({
		type: CREATE_JOBS.START
	})
	try {
		const normalizeQueryParams = mapValues(values, (query) => query || undefined)

		const { data } = await postReq('/api/admin/jobs', null, normalizeQueryParams)

		dispatch({ type: CREATE_JOBS.DONE })
		onSuccess(get(data, 'data.id'))
		return true
	} catch (error) {
		dispatch({ type: CREATE_JOBS.FAILED })
		onFailure(error)
		return false
	}
}

export const updateJob = (
	id: number,
	values: IJobForm,
	onSuccess: () => void = (() => {}),
	onFailure: (error: any) => void = ((error) => {})
) : ThunkResult<Promise<Boolean>> => async (dispatch) => {
	dispatch({
		type: UPDATE_JOBS.START
	})
	try {
		await putReq(`/api/admin/jobs/${id}`, null, values)
		dispatch({ type: UPDATE_JOBS.DONE })

		onSuccess()
		return true
	} catch (error) {
		dispatch({ type: UPDATE_JOBS.FAILED })
		onFailure(error)
		return false
	}
}

// job
export interface IJobPayload {
	data: IJobDetail | null
}
// jobPriceOfferItemDetail
export interface IPriceOfferItem {
	createdAt: string,
	id: number,
	status: PRICE_OFFER_STATUS,
}

export interface IJobDetail {
	id: number
	priceOfferId: number|null
	priceOffers: IPriceOfferItem[]
	address: string
	note: string
	user: IUserDetail
	objectTrusteeEmail: string
	priceForCleaning: number
	ownerEmail: string
	extras: any
	// extras: IExtras

	tuesdayTasks: IUserJobTask[]
	thursdayTasks: IUserJobTask[]
	wednesdayTasks: IUserJobTask[]
	fridayTasks: IUserJobTask[]
	saturdayTasks: IUserJobTask[]
	sundayTasks: IUserJobTask[]
	mondayTasks: IUserJobTask[]
	monthTasks: IUserJobTask[]
	quarterYearTasks: IUserJobTask[]
	halfYearTasks: IUserJobTask[]
	yearTasks: IUserJobTask[]

	jobGroups: IJobGroup[]
	trustees: ITrustee[]
	lastName: string
	phone: string
	role: string
	ratings: IUserRating[]
	isFailure: boolean
}

export interface IUserDetail {
	id: number
	createdAt: number
	lastLoginAt: number
	updatedAt: number
	email: string
	firstName: string
	lastName: string
	phone: string
	role: string
	isConfirmed: boolean
	jobs: any[]
}

export interface ITrustee {
	id: number
	name: string
	sendEmail: false
	phone: string
}

export interface ITrusteeParam {
	userID: number
	sendEmail: false
}

export interface IExtras {
	MONDAY: boolean
	TUESDAY: boolean
	WEDNESDAY: boolean
	THURSDAY: boolean
	FRIDAY: boolean
	SATURDAY: boolean
	SUNDAY: boolean
}

export interface IUserJobTask {
	frequency: string
	order: number
	task: IUserTask
}

export interface IUserTask {
	id: number
	name: string
	nameSlug: string
	organizationID: string
}

export interface IJobGroup {
	address: string
	cleaningSetLocatedOnJob: number
	hasCleaningSet: boolean
	jobID: number
}

export interface IGetJobParams{
	limit: number | null
	page: number | null
	queryParams: any
}

export const getJob = (
	id: number,
	onSuccess: (data: any) => void = ((data) => {}),
	onFailure: (error: any) => void = ((error) => {})
) : ThunkResult<Promise<IJobPayload>> => async (dispatch) => {
	dispatch({
		type: LOAD_JOB.START
	})
	let payload = {} as IJobPayload
	try {
		const { data } = await getReq(`/api/admin/jobs/${id}`)

		payload = {
			data
		} as IJobPayload
		dispatch({
			type: LOAD_JOB.DONE,
			payload
		})

		onSuccess(data)
		return data
	} catch (error) {
		dispatch({
			type: LOAD_JOB.FAILED
		})
		onFailure(error)
		return error
	}
}

export interface IStoreItem {
	id: number,
	name: string,
	count: number,
	organizationName: string,
	organizationId: number,
	createdAt: number,
	updatedAt: number
}

export interface IUnconsumedStockResponse {
	data: IStoreItem[]
}

export const getJobUnconsumedStocks = (
	jobID: number,
	onSuccess: (data: IUnconsumedStockResponse) => void = ((data) => {}),
	onFailure: (error: any) => void = ((error) => {})
) : ThunkResult<Promise<Boolean>> => async (dispatch) => {
	try {
		dispatch({
			type: REMOVE_JOB.START
		})
		const { data } = await getReq(`/api/admin/jobs/${jobID}/unconsumed-stocks`)

		dispatch({
			type: REMOVE_JOB.DONE
		})

		onSuccess(data)
		return true
	} catch (error) {
		dispatch({
			type: REMOVE_JOB.FAILED
		})
		onFailure(error)
		return false
	}
}

export const postStoreItemsConsumeAllFromJob = (
	jobID: number,
	onSuccess: () => void = (() => {}),
	onFailure: (error: any) => void = ((error) => {})
) : ThunkResult<Promise<Boolean>> => async (dispatch) => {
	try {
		dispatch({
			type: REMOVE_JOB.START
		})
		const { data } = await postReq(`/api/admin/store-item-transactions/${jobID}/consume`)

		dispatch({
			type: REMOVE_JOB.DONE
		})

		onSuccess()
		return true
	} catch (error) {
		dispatch({
			type: REMOVE_JOB.FAILED
		})
		onFailure(error)
		return false
	}
}

export const postStoreItemsReturnToVirtualStoreFromJob = (
	jobID: number,
	virtualStoreID: number,
	onSuccess: () => void = (() => {}),
	onFailure: (error: any) => void = ((error) => {})
) : ThunkResult<Promise<Boolean>> => async (dispatch) => {
	try {
		dispatch({
			type: REMOVE_JOB.START
		})
		const { data } = await postReq(`/api/admin/store-item-transactions/${jobID}/to-virtual-store/${virtualStoreID}`)

		dispatch({
			type: REMOVE_JOB.DONE
		})

		onSuccess()
		return true
	} catch (error) {
		dispatch({
			type: REMOVE_JOB.FAILED
		})
		onFailure(error)
		return false
	}
}

export const removeJob = (
	id: number,
	onSuccess: (data: any) => void = ((data) => {}),
	onFailure: (error: any) => void = ((error) => {})
) : ThunkResult<Promise<Boolean>> => async (dispatch) => {
	try {
		dispatch({
			type: REMOVE_JOB.START
		})
		const { data } = await deleteReq(`/api/admin/jobs/${id}`)

		dispatch({
			type: REMOVE_JOB.DONE
		})

		onSuccess(data)
		return true
	} catch (error) {
		dispatch({
			type: REMOVE_JOB.FAILED
		})
		onFailure(error)
		return false
	}
}

export const sendTransferJobToPriceOffer = (
	id: number,
	onSuccess: () => void = (() => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<IUsersPayload>> => async (dispatch) => {
	dispatch({
		type: CREATE_PRICE_OFFERS.START
	})
	try {
		const { data } = await postReq(`/api/admin/jobs/${id}/transfer/to/price-offer`, null, null)

		onSuccess()
		return {}
	} catch (error) {
		onFailure(error)
		return error
	}
}
