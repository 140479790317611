import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import { Input, Spin, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import slugify from 'slugify'
import { TableHeader } from '../../components/TableHeader'
import { FREQUENCIES } from '../../utils/enums'
import { divideArrayFromAnother } from '../../utils/helper'
import { UsePicker } from '../../components/useFrequenciesPicker'
import { getDashboard, IDashboardJob, IDashboardUser, IDashboardUserDetail } from '../../reducers/dashboard/actions'
import StyledLink from '../../components/NavLink'
import Ratings from '../../components/Ratings'
import { loadUser } from '../../reducers/user/userActions'
import { RootState } from '../../reducers'
import FinishesPerMonth from '../../components/statistics/FinishesPerMonth'
import { getUsersJobsTimeWorked } from '../../reducers/usersU/actions'

const { Search } = Input

interface WeekDay {
	name: string;
	key: string;
}

type TJobsKeys =
	| 'fridayJobs'
	| 'mondayJobs'
	| 'saturdayJobs'
	| 'sundayJobs'
	| 'thursdayJobs'
	| 'tuesdayJobs'
	| 'wednesdayJobs'
	| 'monthJobs'
	| 'quarterYearJobs'
	| 'halfYearJobs'
	| 'yearJobs';

const Dashboard = () => {
	const { t } = useTranslation('pages')
	const dispatch = useDispatch()

	const detail = useSelector((state:RootState) => state.dashboard.detail)
	const { isLoading } = detail
	const data: IDashboardUser[] = detail.data?.users || []

	const [allTimeWorkedThisMonthFormatted, setAllTimeWorkedThisMonthFormatted] = useState<string | null | undefined>('')

	const weekNumber = detail.data?.week?.number
	const weekDate = detail.data?.week?.date
	const { filter, Picker } = UsePicker()

	useEffect(() => {
		dispatch(loadUser())
		dispatch(getUsersJobsTimeWorked(((data) => {
			setAllTimeWorkedThisMonthFormatted(data?.data?.allTimeWorkedThisMonthFormatted)
		})))
	}, [dispatch])

	const [searchTerm, setSearchTerm] = useState('')

	const results: IDashboardUser[] = !searchTerm
		? data
		: data?.filter((item: any) => item.user.fullNameSlug.includes(searchTerm))

	// todo filter empty frequencies
	const emptyFrequenciesList: string[] = FREQUENCIES
		.filter((freq) => {
			const jobsKey = (`${freq.key}Jobs`) as TJobsKeys // type-assert

			// Sum up how many jobs exist for this frequency across all users
			const totalJobs = results.reduce((acc, user: IDashboardUser) => {
				const jobsCount = user[jobsKey]?.length || 0
				return acc + jobsCount
			}, 0)

			// Keep this frequency only if totalJobs == 0
			return totalJobs === 0
		})
		.map((freq) => freq.key) || []

	const filterWithoutEmptyFrequecies = filter.filter((item: string) => !emptyFrequenciesList.includes(item))
	const filteredWeek = divideArrayFromAnother(FREQUENCIES, filterWithoutEmptyFrequecies)
	const createDays = filteredWeek.map((day: WeekDay) => ({
		title: i18next.t(`pages:${day.name}`),
		dataIndex: `${day.key}Jobs`,
		key: day.key,
		render: (value: IDashboardJob[]) => value && value.map((row: IDashboardJob) => <NavLink key={row.id} to={`${i18next.t('paths:jobDetail|path')}/${get(row, 'id')}`}
			style={{ display: 'flex', alignItems: 'start', color: row.isJobDone ? 'green' : 'red' }}>{ get(row, 'address')}</NavLink>)
	}))

	const columns: ColumnsType<IDashboardUser> = [
		{
			title: i18next.t('pages:Meno'),
			dataIndex: 'user',
			key: 'name',
			fixed: true,
			// eslint-disable-next-line react/display-name
			render: (row: IDashboardUserDetail) => <StyledLink to={`${i18next.t('paths:userDetail|path')}/${get(row, 'id')}`}
			>{ get(row, 'fullName')}</StyledLink>
		}, ...createDays
	]

	useEffect(() => {
		// @ts-ignore
		dispatch(getDashboard(filter, (data: any) => {
			// eslint-disable-next-line no-use-before-define,no-console
			console.log(filter, data)
		}))
	}, [dispatch, filter])

	const handleChange = (event: { target: { value: string }; }) => {
		const { value } = event.target
		const slug = slugify(value.toLowerCase(), {
			replacement: '-',
			remove: /[^A-Za-z0-9\s-]/g,
			lower: true
		})
		setSearchTerm(slug)
	}

	const titleComponent = () => <TableHeader
		left={
			<div>
				{t('pages: Týždeň')}: {weekNumber}
				<span>    </span>
				{allTimeWorkedThisMonthFormatted ? <div className={'font-semibold mt-5'}><h3>Celkový odpracovaný čas za mesiac: {allTimeWorkedThisMonthFormatted}</h3></div> : <div/>}
			</div>
		}
		right={weekDate}>
		<Search
			placeholder={t('pages: Vyhľadaj meno')}
			allowClear
			onChange={handleChange}
			style={{ width: 200, marginTop: '10px' }}
		/>
		<Picker/>
	</TableHeader>

	return (
		<div className={'page-wrapper'}>
			<Spin spinning={ isLoading }>
				<Table<IDashboardUser>
					columns={columns}
					dataSource={results}
					bordered
					scroll={{ x: filteredWeek.length > 3 ? 2000 : 0 }}
					rowClassName={'antdTable'}
					title={titleComponent}
					pagination={false}
					// footer={() => "Footer"}
				/>
			</Spin>
			<FinishesPerMonth/>
			<Ratings/>
		</div>
	)
}

Dashboard.propTypes = {}

export default Dashboard
