import { get, map, mapValues } from 'lodash'
// eslint-disable-next-line import/no-cycle
import { deleteReq, getReq, postReq, putReq } from '../../utils/request'
import { IResetStore } from '../generalTypes'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'
// eslint-disable-next-line import/no-cycle
import { IUsersPayload } from '../usersU/actions'
import { IResponsePagination } from '../../types/interfaces'
import { formatDateWithTime } from '../../utils/helper'
import { CREATE_PRICE_OFFERS, DELETE_PRICE_OFFER, LOAD_PRICE_OFFER, LOAD_SELECT_PRICE_OFFERS, UPDATE_PRICE_OFFERS } from './types'
import { PRICE_OFFER_STATUS } from '../../utils/enums'
// eslint-disable-next-line import/no-cycle
import { IUserJobTask } from '../jobs/actions'

export type IPriceOfferActions = IResetStore
	| IGetPriceOffersActions
	| IGetPriceOfferAction

// price-offers
export interface IGetPriceOffersActions {
	type: LOAD_SELECT_PRICE_OFFERS
	payload: IPriceOffersPayload
}

export interface IPriceOffersPayload {
	tableData: PriceOfferTableItem[]
	originalData: PriceOfferOriginalItem[]
	pagination: IResponsePagination | null
}

export type PriceOfferTableItem = {
	key: number
	id: number
	createdAt: string
	address: string
	status: string
	updatedAt: string
}

export type PriceOfferOriginalItem = {
	id: number
	createdAt: string
	address: string
	status: string
	updatedAt: string
}

export const getPriceOffers = (
	page: number = 1,
	limit: number|null = 20,
	queryParams: any = {}
): ThunkResult<Promise<IPriceOffersPayload>> => async (dispatch) => {
	let payload = {} as IPriceOffersPayload
	try {
		dispatch({ type: LOAD_SELECT_PRICE_OFFERS.START })

		const queries = {
			limit,
			page,
			...queryParams
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq('/api/admin/price-offers', normalizeQueryParams)

		const originalData: PriceOfferOriginalItem[] = get(data, 'priceOffers', [])
		const tableData: PriceOfferTableItem[] = map(originalData, (column) => ({
			key: column.id,
			id: column.id,
			address: column.address,
			status: column.status,
			createdAt: formatDateWithTime(column.createdAt),
			updatedAt: formatDateWithTime(column.updatedAt)
		}))

		payload = {
			tableData,
			originalData,
			pagination: get(data, 'context')
		}

		dispatch({
			type: LOAD_SELECT_PRICE_OFFERS.DONE,
			payload
		})
		return data
	} catch (error) {
		dispatch({ type: LOAD_SELECT_PRICE_OFFERS.FAILED })
		Promise.reject(error)
		return error
	}
}

export interface IPriceOfferForm {
	id: number| null
	priceOfferHeader: string;
	priceOfferFirmHeaderID: number;
	address: string;
	jobId: number| null; // id of job, if offer is transformed to job
	nameOfHousingAssociation: string;
	comment: string;
	floors: number;
	flats: number;
	nameOfTrustee: string;
	phoneOfTrustee: string;
	emailOfTrustee: string;
	nameOfFirmWhichCleanedUpToNow: string;
	pricePaidForCleaningUpToNow: string;
	isThereRoomForCleaningService: string;
	placeWithWater: string;
	whereCanWaterBePoured: string;
	whenToCall: string;
	price: number;
	dateOfHouseAssociationMeeting: string;
	status: PRICE_OFFER_STATUS,
	updatedAt: string|null,

	mondayTasks: IUserJobTask[];
	tuesdayTasks: IUserJobTask[];
	wednesdayTasks: IUserJobTask[];
	thursdayTasks: IUserJobTask[];
	fridayTasks: IUserJobTask[];
	saturdayTasks: IUserJobTask[];
	sundayTasks: IUserJobTask[];
	monthTasks: IUserJobTask[];
	quarterYearTasks: IUserJobTask[];
	halfYearTasks: IUserJobTask[];
	yearTasks: IUserJobTask[];

	isUznesenieIsPartOfPriceOffer: boolean;
	additionalTexts: string[];
}

export const createPriceOffers = (
	values: IPriceOfferForm,
	onSuccess: (data: any) => void = ((data) => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<IUsersPayload>> => async (dispatch) => {
	dispatch({
		type: CREATE_PRICE_OFFERS.START
	})
	try {
		const normalizeQueryParams = mapValues(values, (query) => query || undefined)

		const { data } = await postReq('/api/admin/price-offers', null, normalizeQueryParams)

		dispatch({ type: CREATE_PRICE_OFFERS.DONE })

		onSuccess(get(data, 'data.id'))
		return get(data, 'data.id')
	} catch (error) {
		dispatch({ type: CREATE_PRICE_OFFERS.FAILED })
		onFailure(error)
		return error
	}
}

export const sendPriceOffer = (
	id: number,
	additionalEmails: string[],
	onSuccess: () => void = (() => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<IUsersPayload>> => async (dispatch) => {
	dispatch({
		type: CREATE_PRICE_OFFERS.START
	})
	try {
		const body = {
			additionalEmails
		}

		const { data } = await postReq(`/api/admin/price-offers/${id}/send/offer/email`, null, body)

		onSuccess()
		return {}
	} catch (error) {
		onFailure(error)
		return error
	}
}

export const sendTransferPriceOfferToJob = (
	id: number,
	onSuccess: () => void = (() => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<IUsersPayload>> => async (dispatch) => {
	dispatch({
		type: CREATE_PRICE_OFFERS.START
	})
	try {
		const { data } = await postReq(`/api/admin/price-offers/${id}/transfer/to/job`, null, null)

		onSuccess()
		return {}
	} catch (error) {
		onFailure(error)
		return error
	}
}

export const sendGetShowPdfPriceOffer = (
	id: number,
	onSuccess: () => void = (() => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<IUsersPayload>> => async (dispatch) => {
	dispatch({
		type: CREATE_PRICE_OFFERS.START
	})
	try {
		const { data } = await getReq(`/api/admin/price-offers/serve/offer/${id}`, null)

		onSuccess()
		return {}
	} catch (error) {
		onFailure(error)
		return error
	}
}

export const updatePriceOffer = (
	id: number,
	values: IPriceOfferForm,
	onSuccess: () => void = (() => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<boolean>> => async (dispatch) => {
	dispatch({
		type: UPDATE_PRICE_OFFERS.START
	})
	try {
		await putReq(`/api/admin/price-offers/${id}`, null, values)
		dispatch({ type: UPDATE_PRICE_OFFERS.DONE })

		onSuccess()
		return true
	} catch (error) {
		dispatch({ type: UPDATE_PRICE_OFFERS.FAILED })
		onFailure(error)
		return error
	}
}

// task
interface IGetPriceOfferAction {
	type: LOAD_PRICE_OFFER
	payload: IPriceOfferPayload
}

export interface IPriceOfferPayload {
	data: IPriceOfferDetail
}

export interface IPriceOfferDetail {
	id: number
	priceOfferHeader: string;
	priceOfferFirmHeaderID: number;

	address: string;
	jobId: number;
	nameOfHousingAssociation: string;
	comment: string;
	floors: number;
	flats: number;
	nameOfTrustee: string;
	phoneOfTrustee: string;
	emailOfTrustee: string;
	nameOfFirmWhichCleanedUpToNow: string;
	pricePaidForCleaningUpToNow: string;
	isThereRoomForCleaningService: string;
	placeWithWater: string;
	whereCanWaterBePoured: string;
	whenToCall: string;
	dateOfHouseAssociationMeeting: string;
	status: PRICE_OFFER_STATUS,
	price: number
	createdAt: string
	updatedAt: string
	organizationID: number

	mondayTasks: IUserJobTask[];
	tuesdayTasks: IUserJobTask[];
	wednesdayTasks: IUserJobTask[];
	thursdayTasks: IUserJobTask[];
	fridayTasks: IUserJobTask[];
	saturdayTasks: IUserJobTask[];
	sundayTasks: IUserJobTask[];
	monthTasks: IUserJobTask[];
	quarterYearTasks: IUserJobTask[];
	halfYearTasks: IUserJobTask[];
	yearTasks: IUserJobTask[];

	isUznesenieIsPartOfPriceOffer: boolean;
	additionalTexts: string[];
}

export const getPriceOffer = (
	id: number,
	onSuccess: (data: IPriceOfferPayload) => void = ((data) => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<IPriceOfferPayload>> => async (dispatch) => {
	let payload = {} as IPriceOfferPayload
	dispatch({
		type: LOAD_PRICE_OFFER.START
	})
	try {
		const { data } = await getReq(`/api/admin/price-offers/${id}`)

		payload = {
			data
		}

		dispatch({
			type: LOAD_PRICE_OFFER.DONE,
			payload
		})

		onSuccess(payload)
		return payload
	} catch (error) {
		dispatch({
			type: LOAD_PRICE_OFFER.FAILED
		})
		onFailure(error)
		return error
	}
}

export interface IFirmHeader {
	id: number,
	firmName: string,
	firmAddress: string,
	firmICO: string,
	firmDIC: string,
	firmImageStampUrl: string
}

export interface IPriceOfferHeaderOptions {
	headers: IFirmHeader[]
}

export const getPriceOfferHeaderOptions = (
	onSuccess: (data: IPriceOfferHeaderOptions) => void = ((data) => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<IPriceOfferHeaderOptions>> => async (dispatch) => {
	try {
		const { data } = await getReq('/api/admin/price-offers/1/header-options') // id not needed, but did not work without it lol

		const payload: IPriceOfferHeaderOptions = {
			headers: data?.data
		}
		onSuccess(payload)
		return payload
	} catch (error) {
		onFailure(error)
		return error
	}
}

export const deletePriceOffer = (
	id: number,
	onSuccess: () => void = (() => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
) : ThunkResult<Promise<boolean>> => async (dispatch) => {
	dispatch({ type: DELETE_PRICE_OFFER.START })
	try {
		await deleteReq(`/api/admin/price-offers/${id}`)

		dispatch({ type: DELETE_PRICE_OFFER.DONE })
		onSuccess()
		return true
	} catch (error) {
		dispatch({ type: DELETE_PRICE_OFFER.FAILED })
		onFailure(error)
		return false
	}
}
