import React, { useEffect, useState } from 'react'
import { Col, DatePicker, Row } from 'antd'
import { useDispatch } from 'react-redux'
import PriceOffersSentPerMonth from './PriceOffersSentPerMonth'
import PriceOffersStatusPerMonth from './PriceOffersCompletedPerMonth'
import SelectUserField from '../../../components/SelectUserField'

const { RangePicker } = DatePicker

const StatisticsDashboard = () => {
	const [dateRange, setDateRange] = useState<any>([])
	const [employeeID, setEmployeeID] = useState<number | null>(null) // State for selected employee
	const dispatch = useDispatch()

	useEffect(() => {
		const params: any = {
			startDate: dateRange[0]?.startOf('month').toISOString(),
			endDate: dateRange[1]?.endOf('month').toISOString()
		}

		if (employeeID) {
			params.employeeID = employeeID // Add employee filter to params
		}

		// Dispatch the params for filtered data
		// Example: dispatch(yourFetchStatisticsAction(params))
	}, [dateRange, employeeID, dispatch])

	const handleDateRangeChange = (dates: any) => {
		setDateRange(dates)
	}

	const handleEmployeeChange = (data: any) => {
		setEmployeeID(data.value)
	}

	return (
		<div>
			<Row justify="space-between" style={{ marginBottom: 16 }}>
				<Col>
					{/* User Picker */}
					<SelectUserField
						onValueChange={handleEmployeeChange}
						userValue={employeeID || undefined}
					/>
				</Col>
				<Col>
					{/* Date Picker */}
					<RangePicker picker="month" onChange={handleDateRangeChange} />
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<PriceOffersSentPerMonth />
				</Col>
				<Col span={24}>
					<PriceOffersStatusPerMonth
						startDate={dateRange[0]?.startOf('month').toISOString()}
						endDate={dateRange[1]?.endOf('month').toISOString()}
					/>
				</Col>
			</Row>
		</div>
	)
}

export default StatisticsDashboard
