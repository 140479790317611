import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { Select, Tag } from 'antd'
import { WrappedFieldProps } from 'redux-form'
import { InputProps } from 'antd/lib/input'
import { FormItemLabelProps } from 'antd/lib/form/FormItemLabel'
import { useDispatch } from 'react-redux'
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'
import { PlusOutlined } from '@ant-design/icons'
import i18next from 'i18next'
// eslint-disable-next-line import/no-cycle
import { getJobsStoreItems, IJobsStoreItemsPayload } from '../../../reducers/jobs/actions'
// eslint-disable-next-line import/no-cycle
import { STORE_TYPE } from '../../stores/Stores'
// eslint-disable-next-line import/no-cycle
import { colorHash } from '../StoreItems'
// eslint-disable-next-line import/no-cycle
import { getPriorityForStoreType } from '../components/AddressWithStocksTable'

type Props = WrappedFieldProps & InputProps & FormItemLabelProps & any & {
	label?: string,
	filterStoreWithZeroStock: boolean,
	showLabel?: string,
	placeholder?: string,
	customClass?: string,
	customInputClass?: string,
	customLabelClass?: string,
	required?: boolean,
	allowClear?: boolean
} & {
	onlyVirtualStores?: boolean,
	storeItemID?: number,
	storeType?: STORE_TYPE | null
}

const { Option } = Select

const JobSelectField = (props: Props) => {
	const {
		input,
		label,
		filterStoreWithZeroStock,
		placeholder,
		showLabel,
		customClass,
		customLabelClass,
		meta: { touched, error },
		type,
		disabled,
		onInput,
		onKeyUp,
		suffix,
		required,
		min,
		onBlur,
		width,
		multiple,
		onlyVirtualStores,
		storeItemID,
		storeType,
		...rest
	} = props

	const dispatch: any = useDispatch()

	const { t } = useTranslation('pages')

	const [query, setQuery] = useState({
		search: '',
		limit: 100,
		page: 1
	})

	const [jobStoreItemsList, setStoreItemsList] = useState<IJobsStoreItemsPayload | null>(null)

	useEffect(() => {
		dispatch(getJobsStoreItems({
			limit: query.limit,
			page: query.page,
			queryParams: {
				search: query.search,
				storeItemID,
				storeType: storeType || null
			}
		}, (data: IJobsStoreItemsPayload) => {
			setStoreItemsList(data)
		}))
	}, [query.search, storeType])

	const checkValue = () => {
		if (input.value) {
			return input.value
		}

		if (multiple) {
			return []
		}

		return input.value
	}

	const onInputChange = (value: any) => {
		if (multiple) {
			input.onChange(value || [])
		} else {
			input.onChange(value || null)
		}
	}

	const onSearch = (value: any) => {
		setQuery(
			{
				...query,
				search: value,
				page: 1
			}
		)
	}

	return (
		<div className={ cx('input-wrapper', { error: touched && error }, customClass) }>
			<label className={ cx(customLabelClass, { required }) }>{label || t('components:Adresa')}</label>
			<div className={ 'input-field min-w-full' }>
				<Select
					{ ...input }
					value={ checkValue() }
					labelInValue={ false }
					placeholder={ placeholder }
					type={ type }
					disabled={ disabled }
					className={ 'min-w-full' }
					onInput={ onInput }
					onKeyUp={ onKeyUp }
					required={ required }
					min={ min }
					onBlur={ onBlur }
					suffix={ suffix }
					filterOption={false}
					style={ { width } }
					onSearch={ onSearch }
					onChange={ onInputChange }
					notFoundContent={ <div><PlusOutlined/> { i18next.t('pages:Vytvorte si virtualny sklad') } <b style={ { color: 'black' } }></b></div> }
					{ ...rest }
				>
					{jobStoreItemsList?.jobs?.length !== 0
					&& map(jobStoreItemsList?.jobs?.filter(
						(job) => job.storeItemsCount > 0 || !filterStoreWithZeroStock
					), (option) => (
						<Option
							key={ option.id || 0 }
							value={ option.id || 0 }
							title={ option.address}
							label={ option.address }
						>
							<div className={'w-full flex justify-between'}>
								<span className={'w-16'}>{ option.address }</span>
								{ option.storeType && <Tag color={colorHash(option.storeType || '')}>{getPriorityForStoreType(option.storeType, t)}</Tag> }
								{ option.storeItemsCount && <span>{ option.storeItemsCount }</span>}
							</div>
						</Option>
					))
					}
				</Select>
			</div>
			<div className={ 'tooltip-error' }>
				{ touched ? error : '' }
			</div>
		</div>
	)
}

export default JobSelectField
