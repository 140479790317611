import React, { useEffect } from 'react'
import { Field, getFormValues, initialize, InjectedFormProps, reduxForm } from 'redux-form'
import { Col, Form, Radio, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { SwapOutlined } from '@ant-design/icons'
import { gray } from 'tailwindcss/colors'
import { FORMS, STORE_ITEM_TRANSACTION_TYPE, translateStoreItemTransactionTypeToSk } from '../../../utils/enums'
import TextField from '../../../atoms/form/TextField'
import validateCreateStoreItemTransactionForm from './validateCreateStoreItemTransactionForm'
// eslint-disable-next-line import/no-cycle
import JobSelectField from './JobSelectField'
// eslint-disable-next-line import/no-cycle
import { ICreateStoreItemTransactionForm } from '../../../reducers/storeItemTransactions/actions'
import RadioSelectField from '../../../atoms/form/RadioSelectField'
// eslint-disable-next-line import/no-cycle
import { STORE_TYPE } from '../../stores/Stores'
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../../reducers'

type ComponentProps = {
	isCreate: boolean,
	onTransactionTypeChanged: (type: STORE_ITEM_TRANSACTION_TYPE) => void
}

type Props = InjectedFormProps<ICreateStoreItemTransactionForm, ComponentProps> & ComponentProps

const CreateStoreItemTransactionForm = (props: Props) => {
	const { t } = useTranslation('components')
	const dispatch: any = useDispatch()
	const {
		handleSubmit
	} = props
	const hasPermissionToAddStoreItems = useSelector((state: RootState) => state.profile.profile.data?.hasPermissionToAddStoreItems)
	const storeItemForm: ICreateStoreItemTransactionForm = useSelector((state) => getFormValues(FORMS.CREATE_STORE_ITEM_TRANSACTION_FORM)(state)) as ICreateStoreItemTransactionForm
	const storeTransactionType = storeItemForm?.type

	useEffect(() => { props.onTransactionTypeChanged(storeTransactionType) }, [storeTransactionType])

	const transactionOptions = hasPermissionToAddStoreItems
		? [STORE_ITEM_TRANSACTION_TYPE.IN, STORE_ITEM_TRANSACTION_TYPE.REGULAR, STORE_ITEM_TRANSACTION_TYPE.CONSUME]
		: [STORE_ITEM_TRANSACTION_TYPE.REGULAR, STORE_ITEM_TRANSACTION_TYPE.CONSUME]

	return (
		<Form onSubmitCapture={ handleSubmit }>
			<Row gutter={ 16 }>
				<Col span={ 24 } className={ 'grid' }>
					<div className={ 'flex direction-col justify-start main-content' }>
						<Field
							name={ 'type' }
							component={ RadioSelectField }
							label={ t('components:Typ transakcie') }
							defaultValueKey={ STORE_ITEM_TRANSACTION_TYPE.REGULAR }
							required={true}
							options={ map(transactionOptions, (transaction) => (
								<Radio.Button
									key={ transaction }
									value={ transaction }
								>
									{ translateStoreItemTransactionTypeToSk(transaction) }
								</Radio.Button>
							))
							}
						/>
						<Field
							name={ 'amount' }
							component={ TextField }
							type={'number'}
							label={ t('components:Množstvo') }
							size={ 'large' }
							required
						/>
						{(storeTransactionType === STORE_ITEM_TRANSACTION_TYPE.REGULAR || storeTransactionType === STORE_ITEM_TRANSACTION_TYPE.CONSUME) && (
							<Field
								name={ 'jobFromID' }
								component={ JobSelectField }
								storeType={null}
								storeItemID={storeItemForm?.storeItemID}
								label={'Adresa z'}
								size={ 'large' }
								filterStoreWithZeroStock={true}
								allowClear
								showSearch
							/>
						)}
						{ (storeTransactionType === STORE_ITEM_TRANSACTION_TYPE.REGULAR && <span className={'w-full flex justify-center'}>
							<SwapOutlined
								className={'m-1'} twoToneColor={gray['300']}
								onClick={() => {
									dispatch(initialize(FORMS.CREATE_STORE_ITEM_TRANSACTION_FORM, {
										...storeItemForm,
										jobFromID: storeItemForm.jobToID, // Set jobFromID to the current jobToID
										jobToID: storeItemForm.jobFromID // Set jobToID to the current jobFromID
									}))
								}}
							/>
						</span>) }
						{(storeTransactionType === STORE_ITEM_TRANSACTION_TYPE.REGULAR || storeTransactionType === STORE_ITEM_TRANSACTION_TYPE.IN) && (
							<Field
								name={'jobToID'}
								component={JobSelectField}
								storeItemID={null}
								storeType={storeTransactionType === STORE_ITEM_TRANSACTION_TYPE.IN ? STORE_TYPE.VIRTUAL_STORE : null}
								label={'Adresa do'}
								size={'large'}
								allowClear
								showSearch
							/>
						)}
					</div>
				</Col>
			</Row>
		</Form>
	)
}

const form = reduxForm<ICreateStoreItemTransactionForm, ComponentProps>({
	form: FORMS.CREATE_STORE_ITEM_TRANSACTION_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validateCreateStoreItemTransactionForm
})(CreateStoreItemTransactionForm)

export default form
